import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Slider from '../../components/slider/SliderParallaxTyper';
import About from '../../components/about/AboutParallaxTyper';
import Service from '../../components/service/ServiceAnimation';
import Portfolio from '../../components/portfolio/CreativePortfolio';
import Testimonial from '../../components/testimonial/Testimonial';
import Blog from '../../components/blog/BlogAnimation';
import Contact from '../../components/Contact';
import Footer from '../../components/footer/Footer';
import Address from '../../components/Address';
import Portfolio2 from '../../components/portfolio/ProjectSection';

const HomeParallaxTyper = () => {
  document.body.classList.add('light');
  return (
    <div className='home-light'>
      <div
        className='demo-fixed-wrapper'
        data-aos='fade-left'
        data-aos-duration='1200'
        data-aos-delay='50'
      >
        {/* <button className="demo-button">
          <Link to="/">
            <span className="text"></span>
          </Link>
        </button> */}
      </div>
      {/* demo show wrapper  */}

      <Header />
      {/* End Header */}

      <Slider />
      {/* End Slider */}

      <div className='beny_tm_about' id='about'>
        <div className='container'>
          <div className='beny_tm_title_holder'>
            <span>About Me</span>
            <h2>About Me</h2>
            <p>
              I'm Ajmal Hussain, a Full Stack Developer with 2 years of
              experience in Javascript & React. I blend creativity with
              functionality to craft engaging designs. Choosing me means
              transforming ideas into results. Let's collaborate to enhance your
              digital presence. Reach me at my email to discuss your project.
            </p>
          </div>
          {/* End .beny_tm_title */}
          <About />
          {/* End Slider */}
        </div>
      </div>
      {/* /ABOUT */}

      {/* SERVICES */}
      <div className='beny_tm_services' id='service'>
        <div className='container'>
          <div className='beny_tm_title_holder'>
            <span>Services</span>
            <h2>I provide wide range of digital services</h2>
            <p>
              As a seasoned Full Stack Developer, I offer a range of
              comprehensive services to bring your digital projects to fruition.
              With proficiency in both front-end and back-end technologies, I
              deliver end-to-end solutions that cater to your unique needs.
            </p>
          </div>
          {/* End beny_tm_title */}
          <Service />
        </div>
      </div>
      {/* /SERVICES */}

      {/* PORTFOLIO */}
      <div className='beny_tm_portfolio' id='portfolio'>
        <div className='container'>
          <div className='beny_tm_title_holder'>
            <span>Projects</span>
            <h2>My Projects</h2>
            <p>
              Explore my portfolio of key projects, showcasing my skills and
              creativity in software development and design. Each project
              highlights my commitment to quality and innovation. Dive in to see
              how I bring ideas to life.
            </p>
          </div>
          {/* End beny_tm_title */}
          <Portfolio />
        </div>
      </div>

      {/* /PORTFOLIO */}
      {/* /PORTFOLIO 2 */}

      <div className='beny_tm_portfolio' id='portfolio'>
        <div className='container'>
          <div className='beny_tm_title_holder'>
            <span>Projects 2</span>
            <h2>My Projects 2</h2>
            <p>
              Explore my portfolio of key projects, showcasing my skills and
              creativity in software development and design. Each project
              highlights my commitment to quality and innovation. Dive in to see
              how I bring ideas to life.
            </p>
          </div>
          {/* End beny_tm_title */}
          <Portfolio2 />
        </div>
      </div>
      {/* /PORTFOLIO 2 */}

      {/* TESTIMONIALS */}
      <div className='beny_tm_testimonials'>
        <div className='container'>
          <div className='beny_tm_title_holder'>
            <span>Testimonials</span>
            <h2>What's Clients Say.</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
              egestas. Id fermentum nullam ipsum massa.
            </p>
          </div>
          {/* End beny_tm_title */}
          <div
            className='testimonials_list'
            data-aos='fade-right'
            data-aos-duration='1200'
            data-aos-delay='100'
          >
            <Testimonial />
          </div>
        </div>
      </div>
      {/* /TESTIMONIALS */}

      {/* NEWS */}
      <div className='beny_tm_news' id='news'>
        <div className='container'>
          <div className='beny_tm_title_holder'>
            <span>Blogs</span>
            <h2>Recent News</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
              egestas. Id fermentum nullam ipsum massa.
            </p>
          </div>
          {/* End beny_tm_title */}
          <Blog />
        </div>
      </div>
      {/* /NEWS */}

      {/*  CONTACT */}
      <div className='beny_tm_contact' id='contact'>
        <div className='container'>
          <div className='contact_inner'>
            <div
              className='left'
              data-aos='fade-right'
              data-aos-duration='1200'
            >
              <div className='beny_tm_title_holder'>
                <span>Contact</span>
                <h2>Let's discuss your project</h2>
              </div>
              <div className='short_list'>
                <Address />
              </div>
            </div>
            {/* End .left */}

            <div
              className='right'
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='150'
            >
              <div className='title'>
                <p>
                  I'm always open to discussing product
                  <br />
                  <span>design work or partnerships.</span>
                </p>
              </div>
              <div className='fields'>
                <Contact />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /CONTACT */}

      {/* COPYRIGHT */}
      <div className='beny_tm_copyright'>
        <div className='container'>
          <Footer />
        </div>
      </div>
      {/* /COPYRIGHT */}
    </div>
  );
};

export default HomeParallaxTyper;
