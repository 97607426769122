import React from 'react';
import Skills from '../skills/SkillsAnimation';

const AboutParallax = () => {
  return (
    <>
      <div className='about_inner'>
        <div className='left'>
          <img src='img/thumbs/1-1.jpg' alt='img' />
          <div
            className='image'
            data-aos='fade-right'
            data-aos-duration='1200'
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + 'img/about/about1.svg'
              })`,
            }}
          ></div>
        </div>
        <div
          className='right'
          data-aos='fade-right'
          data-aos-duration='1200'
          data-aos-delay='50'
        >
          <div className='short'>
            <h3> I'm Ajmal Hussain</h3>
            <h5>
              A Lead <span className='theme-color'>Full Stack Developer </span>
              based in <span className='theme-color'>India</span>
            </h5>
            <p>
              I design and develop services for customers specializing creating
              stylish, moder n websites, web services and online stores. My
              passion is to design digital user experiences through meaningful
              interactions. Check out my Portfolio
            </p>
          </div>
          <div className='extra'>
            <h3 className='title'>Personal Info</h3>
            <div className='list'>
              <ul>
                <li>
                  <p>
                    <span>Name :</span> Ajmal Hussain
                  </p>
                </li>
                <li>
                  <p>
                    <span>Address :</span> New Delhi.
                  </p>
                </li>
                <li>
                  <p>
                    <span>Age :</span> 24 Years
                  </p>
                </li>
                <li>
                  <p>
                    <span>Phone :</span> +91 822 880 5415
                  </p>
                </li>
                <li>
                  <p>
                    <span>Nationality :</span> India
                  </p>
                </li>
                <li>
                  <p>
                    <span>Email :</span> ajmal0ktr@gmail.com
                  </p>
                </li>
                <li>
                  <p>
                    <span>Freelance :</span> Available
                  </p>
                </li>
                <li>
                  <p>
                    <span>Languages :</span> Hindi, English
                  </p>
                </li>
              </ul>
            </div>
            <div className='beny_tm_button color-outline'>
              <a href='/Profile.pdf' download>
                <span className='wrapper'>
                  <span className='first'>Download CV</span>
                  <span className='second'>Download CV</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Skills />
    </>
  );
};

export default AboutParallax;
