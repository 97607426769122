import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/v1/create-contectUs',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success('Message submitted successfully!');
      reset();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('There was an error submitting the form!');
      }
    }
  };

  return (
    <>
      <form className='contact_form' onSubmit={handleSubmit(onSubmit)}>
        <div className='first_row'>
          <input
            {...register('name', { required: true })}
            type='text'
            placeholder='Name *'
          />
          {errors.name && (
            <span className='invalid-feedback'>Name is required</span>
          )}
        </div>
        {/* End .first_row */}

        <div className='second'>
          <div className='left'>
            <input
              {...register('email', {
                required: 'Email is Required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
              type='email'
              placeholder='Email *'
            />
            {errors.email && (
              <span className='invalid-feedback'>{errors.email.message}</span>
            )}
          </div>
          <div className='right'>
            <input
              {...register('subject', { required: true })}
              placeholder='Subject *'
            />
            {errors.subject && (
              <span className='invalid-feedback'>Subject is required.</span>
            )}
          </div>
        </div>
        {/* End .second */}

        <div className='third'>
          <textarea
            {...register('message', { required: true })}
            placeholder='Message *'
          ></textarea>
          {errors.message && (
            <span className='invalid-feedback'>Message is required.</span>
          )}
        </div>
        {/* End .third */}

        <div className='beny_tm_button'>
          <button type='submit' className='color'>
            <span className='wrapper'>
              <span className='first'>Submit</span>
              <span className='second'>Submit</span>
            </span>
          </button>
        </div>
        {/* End beny_tm_button */}
      </form>
      <ToastContainer />
    </>
  );
};

export default Contact;
