import React, { useState } from 'react';
import 'photoswipe/dist/photoswipe.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  CardActionArea,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import image from '../../assets/Portfolio img/Images/Screenshot 2024-07-27 010816.png';
import { ReactComponent as ReactIcon } from '../../assets/Portfolio img/react-svgrepo-com.svg';
import { ReactComponent as MongoIcon } from '../../assets/Portfolio img/mongodb-icon.svg';
import { ReactComponent as NodeIcon } from '../../assets/Portfolio img/node-js-svgrepo-com.svg';
import { ReactComponent as ExpressIcon } from '../../assets/Portfolio img/icons8-express-js.svg';
import { ReactComponent as MateUi } from '../../assets/Portfolio img/icons8-material-ui.svg';
import { ReactComponent as LinkIon } from '../../assets/Portfolio img/Icon/link-square-svgrepo-com.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ProjectSection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className='flex justify-items-start  portfolio_list'>
        <div data-aos='fade-right' data-aos-duration='1200' className='mr-6'>
          <Card sx={{ maxWidth: 545, position: 'relative' }}>
            <CardActionArea onClick={handleOpen} className='group'>
              <div className='relative '>
                <CardMedia
                  component='img'
                  height='140'
                  image={image}
                  alt='green Iguana'
                  className='relative'
                />
                <div className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-70 p-4 opacity-0 sm:group-hover:opacity-100 transition-opacity duration-700 sm:animation'>
                  <Typography
                    gutterBottom
                    variant='h4'
                    textAlign={'center'}
                    component='div'
                    className='text-[#f52225] fade-in-up'
                  >
                    e-Commerce (Multivendor)
                    <Tooltip title='App Link' arrow placement='right'>
                      <a
                        href='https://abohalalmarketcanton.store/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='ml-2'
                      >
                        <LinkIon className='w-7 h-7 text-white inline' />
                      </a>
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant='body2'
                    textAlign={'center'}
                    className='text-slate-300 fade-in-up'
                    style={{ animationDelay: '0.2s' }}
                  >
                    This is an e-commerce web app built using MERN stack. In
                    this e-commerce web app we can create multiple seller
                    accounts.
                  </Typography>
                </div>
              </div>
            </CardActionArea>
            <CardContent>
              <div className='flex items-center justify-start mt-2 sm:flex-wrap md:flex-nowrap'>
                <h3 className='font-semibold font-poppins mr-2'>Build with </h3>
                <Tooltip title='MongoDB' arrow>
                  <MongoIcon className='h-8 w-8 mx-1' />
                </Tooltip>
                <Tooltip title='React JS' arrow>
                  <ReactIcon className='h-8 w-8 mx-1' />
                </Tooltip>
                <Tooltip title='Node JS' arrow>
                  <NodeIcon className='h-8 w-8 mx-1' />
                </Tooltip>
                <Tooltip title='Express JS' arrow>
                  <ExpressIcon className='h-8 w-8 mx-1' />
                </Tooltip>
                <Tooltip title='Material UI' arrow>
                  <MateUi className='h-8 w-8 mx-1' />
                </Tooltip>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          <div className='font-bold text-2xl'>Project Details</div>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className='relative w-full'>
            <div>
              <img
                src={image}
                alt='Project'
                className='w-full shadow h-auto mb-4'
              />
              <p className='text-gray-700 mb-4'>
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica.
              </p>
            </div>
          </div>

          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ProjectSection;
