import React from 'react';

const Address = () => {
  return (
    <ul>
      <li>
        <img className='svg' src='img/svg/phone.svg' alt='' />
        <a href='Tel: 8228805415'>+91 8228805415</a>
      </li>
      {/* End li ;lfkmewl*/}

      <li>
        <img className='svg' src='img/svg/mail.svg' alt='' />
        <span>
          <a href='mailto:ajmal0ktr@gmail.com'>ajmal0ktr@gmail.com</a>
        </span>
      </li>
      {/* End li */}

      <li>
        <img className='svg' src='img/svg/map.svg' alt='' />
        <span>
          H-758, Jaithpur, South Delhi
          <br /> 110044 India
        </span>
      </li>
    </ul>
  );
};

export default Address;
