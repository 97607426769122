import React, { useEffect } from 'react';
import AllRoutes from './router/AllRoutes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init();

    const loadScript = () => {
      const script = document.createElement('script');
      script.src = 'https://automatic.chat/embed.js';
      script.async = true;
      script.id = 'cm0hxqn18002gskn09z0o9hcz';
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        loadScript();
      }
    };

    // Initial load
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='beny_tm_all_wrap'>
      {/* <ScrollToTop /> */}
      <AllRoutes />
    </div>
  );
};

export default App;
