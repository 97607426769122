import React from 'react';

const socialList = [
  {
    name: 'FACEBOOK.',
    link: 'https://www.facebook.com/',
  },
  {
    name: 'LINKEDIN.',
    link: 'https://linkedin.com/',
  },
  {
    name: 'INSTAGRAM.',
    link: 'https://www.instagram.com/',
  },
  {
    name: 'TWITTER.',
    link: 'https://twitter.com/',
  },
];

const SocialTwo = () => {
  return (
    <ul>
      {socialList.map((val, i) => (
        <li key={i}>
          <a href={val.link} target='_blank' rel='noreferrer'>
            <span className='first'>{val.name}</span>
            <span className='second'>{val.name}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialTwo;
